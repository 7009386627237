import React, { useState, useEffect } from "react";
import queryString from "query-string";
import axios from "axios";
import { parse, format, parseISO, isAfter, addMinutes } from "date-fns";
import { navigate } from "gatsby";
// import { Image, Placeholder } from "cloudinary-react";
import { Spinner } from "react-bootstrap";

// seo
import Seo from "../components/Seo";

// app-header
import AppHeader from "../components/AppHeader/AppHeader";

// config
import config from "../config";

// email
// import { sendEmailNotifecation } from "../services/SendEmail";

// components
import { WebinarHeader } from "../components/Webinar/WebinarHeader";
import WebinarHost from "../components/Webinar/WebinarHost";
import WebinarBenifits from "../components/Webinar/WebinarBenifits";
import KeyTakeAways from "../components/Webinar/KeyTakeAways";
import OurSuccess from "../components/Webinar/OurSuccess";
// import Reviews from "../components/Webinar/Reviews";
import WebinarForm from "../components/Webinar/WebinarFormV2";
import WebinarLiveCount from "../components/Webinar/WebinarLiveCount";
// import { WebinarTimer } from "../components/Webinar/WebinarTimer";

import SuccessStories from "../components/Home/SuccessStories/SuccessStories";

// hooks
import { useWebinarQuery } from "../hooks/useWebinarQuery";

// services
import { sendToLSQ } from "../services/LeadSquared";
// import { sendToCT } from "../services/Clevertap";
import { sendToNudgify } from "../services/Nudgify";

// Api's Actions
import { handleUserWebinarDataToDxBackend } from "../actions/webinarActions";

// css
import * as styles from "../components/Webinar/webinar.module.css";
// import AppHeaderTwinApp from "../components/AppHeader/AppHeaderTwinApp";

const WebinarFreePage = props => {
  const currentTime = new Date();
  const node = useWebinarQuery();
  const serviceId = node[0].productId;
  const webinarData = { counter: node[0].timer };

  // const { appHeader, twinApp } = queryString.parse(props.location.search);

  const [clientId, setClientId] = useState("");
  const [schedular, setSchedular] = useState([]);
  const [speaker, setSpeaker] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const [sellingPrice, setSellingPrice] = useState(null);
  const [costPrice, setCostPrice] = useState(null);
  const [coupanEnabled, setCoupanEnabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [serverErr, setServerErr] = useState(null);

  const [couponList, setCouponList] = useState(null);
  const [coupon, setCoupon] = useState("");
  const [couponPlaceholder, setCouponPlaceholder] = useState("");
  const [discountAmount, setDiscountAmount] = useState(null);
  const [webinarTopic, setWebinarTopic] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [schedularId, setSchedularId] = useState(null);
  const [webinarTiming, setWebinarTiming] = useState(null);
  const [webinarTimeSave, setWebinarTimeSave] = useState(null);

  const [webinarTopicErr, setWebinarTopicErr] = useState(null);
  const [webinarTimingErr, setWebinarTimingErr] = useState(null);

  const [redirectWebinarTiming, setRedirectWebinarTiming] = useState("");
  const [LSQWebinarTiming, setLSQWebinarTiming] = useState("");
  const [showForm, setShowForm] = useState(false);

  const [isHostLoading, setHostLoading] = useState(true);
  const [defaultCoupon, setSefaultCoupon] = useState([]);

  const [appHeader, setAppHeader] = useState(null);
  const [twinApp, setTwinApp] = useState(null);

  const [showCostPrice] = useState(false);

  // utils functions
  const getCallbackUrl = (name) => {
    const params = queryString.parse(props.location.search);
    const pixelClickId = params.pixel_clickid || "";

    const webinarPrice = coupanEnabled ? sellingPrice : costPrice;

    const url =
      "/reversal-thanking/?name=" +
      name +
      "&webinarTiming=" +
      redirectWebinarTiming +
      "&schedularId=" +
      schedularId +
      "&webinarTopic=" +
      selectedTopic +
      "&webinarPrice=" +
      webinarPrice +
      "&webinarType=" +
      "freeWebinar" +
      "&pixelClickId=" +
      pixelClickId +
      (params.twinApp && params.twinApp === "yes" ? "&twinApp=yes" : "");

    return url;
  };

  const getFirstSlot = arr => {
    let dateArr = [];
    arr.forEach(item => {
      let availableSlot = [];
      item.slots.forEach(slot => {
        if (slot.bookedSeats < slot.totalSeats) {
          if (slot.cutOffTime) {
            if (isAfter(parseISO(slot.cutOffTime), new Date())) {
              availableSlot.push(slot);
            }
          } else {
            availableSlot.push(slot);
          }
        }
      });
      dateArr.push({
        myDate: format(parse(item.date, "dd-MM-yyyy", new Date()), "dd"),
        day: format(parse(item.date, "dd-MM-yyyy", new Date()), "EEE"),
        date: format(parse(item.date, "dd-MM-yyyy", new Date()), "dd-MM-yyyy"),

        availableSlot: availableSlot,
      });
    });
    return dateArr;
  };

  const getClientData = async clientId => {
    const options = {
      method: "get",
      url: config.DX_API_BASE_URL + "/api/clients/details/" + clientId,
      headers: {
        Authorization: `Bearer ${config.JWT_TOKEN}`,
      },
    };

    try {
      const res = await axios(options);
      const user = res.data.results[0];
      setClientId(user.clientId);
      setName(user.name);
      setEmail(user.email);
      setPhone(user.phone);
    } catch (error) {
      console.log("Error While Fetching Client Info", error);
    }
  };

  // get client info
  useEffect(() => {
    if (props.location.search) {
      const params = queryString.parse(props.location.search);
      if (params.appHeader) {
        setAppHeader(params.appHeader);
      }
      if (params.twinApp) {
        setTwinApp(params.twinApp);
      }
      if (params.clientId) {
        getClientData(params.clientId);
      }
    }
  }, [props.location.search]);

  // get schedular by product id
  useEffect(() => {
    const getTopic = async () => {
      const options = {
        method: "get",
        url: config.DX_API_BASE_URL + "/api/products/" + serviceId,
        headers: {
          Authorization: `Bearer ${config.JWT_TOKEN}`,
        },
      };

      try {
        const res = await axios(options);
        // setShowLoader(false);
        setSellingPrice(res.data.results.sellingPrice);
        setCostPrice(res.data.results.costPrice);
        if (Array.isArray(res.data.results.name)) {
          setWebinarTopic(res.data.results.name);
          setSelectedTopic(res.data.results.name[0]);
        } else {
          setWebinarTopic([res.data.results.name]);
          setSelectedTopic(res.data.results.name);
        }
      } catch (error) {
        setServerErr(error.data.msg);
        setShowLoader(false);
      }
    };

    const getSchedular = async () => {
      const options = {
        method: "get",
        url:
          config.DX_API_BASE_URL + "/api/schedular/productByDate/" + serviceId,
        headers: {
          Authorization: `Bearer ${config.JWT_TOKEN}`,
        },
      };

      try {
        const res = await axios(options);
        setSchedular(res.data.results);

        if (res.data.results && res.data.results.length === 0) {
          setHostLoading(false);
        }

        let dateArr = getFirstSlot(res.data.results);
        if (dateArr.length > 0) {
          setSchedularId(dateArr[0].availableSlot[0].id);
          setRedirectWebinarTiming(
            format(
              parse(dateArr[0].date, "dd-MM-yyyy", new Date()),
              "dd-MM-yyyy"
            ) +
              " " +
              format(
                parseISO(dateArr[0].availableSlot[0].startDate),
                "hh:mm aaa"
              ) +
              " to " +
              format(
                parseISO(dateArr[0].availableSlot[0].endDate),
                "hh:mm aaa"
              ) +
              ", " +
              dateArr[0].availableSlot[0].language
          );
          setWebinarTimeSave(dateArr[0].availableSlot[0].startDate);
          setWebinarTiming(
            format(
              parse(dateArr[0].date, "dd-MM-yyyy", new Date()),
              "dd MMM, EEE"
            ) +
              ", " +
              format(
                parseISO(dateArr[0].availableSlot[0].startDate),
                "hh:mm aaa"
              ) +
              ", " +
              dateArr[0].availableSlot[0].language
          );
          setLSQWebinarTiming(parseISO(dateArr[0].availableSlot[0].startDate));
        }
      } catch (error) {
        console.error("schedular server error", error);
      }
    };

    const getCoupons = async () => {
      const options = {
        method: "get",
        url:
          config.DX_API_BASE_URL +
          "/api/marketing/coupons?size=3&pId=" +
          serviceId,
        headers: {
          Authorization: `Bearer ${config.JWT_TOKEN}`,
        },
      };

      try {
        const res = await axios(options);
        setSefaultCoupon(res.data.results);
      } catch (error) {
        console.error("coupon server error", error);
        setShowLoader(false);
      }
    };

    const initCall = async () => {
      const promises = [getSchedular(), getTopic(), getCoupons()];
      await Promise.all(promises);
    };

    if (serviceId) {
      initCall();
    }
  }, [serviceId]);

  // if slots change then change the speaker details
  useEffect(() => {
    const getSlots = async slotId => {
      const options = {
        method: "get",
        url: config.DX_API_BASE_URL + "/api/schedular/speaker/" + slotId,
        headers: {
          Authorization: `Bearer ${config.JWT_TOKEN}`,
        },
      };

      try {
        const res = await axios(options);
        setSpeaker(res.data.results);
        setHostLoading(false);
      } catch (error) {
        console.error("slot server error", error);
        setHostLoading(false);
      }
    };
    if (schedular && schedular.length > 0) {
      const slotId = schedular[0].slots[0].id;
      getSlots(slotId);
    }
  }, [schedular]);

  // To fetch list of available coupons
  useEffect(() => {
    if (costPrice) {
      const results = defaultCoupon;
      let resultData = results.filter(obj => {
        return obj.priority === 1;
      });

      if (resultData.length !== 0) {
        const newSellingPrice =
          parseInt(costPrice) - parseInt(resultData[0].discountedAmount);
        setCoupon(resultData[0].couponCode);
        setCouponPlaceholder(resultData[0].couponCode);
        setCouponList(results);
        setSellingPrice(newSellingPrice);
        setCoupanEnabled(true);
        setDiscountAmount(resultData[0].discountedAmount);
        setShowLoader(false);
      } else {
        setCouponList(results);
        setSellingPrice(parseInt(costPrice));
        setCoupanEnabled(false);
        setShowLoader(false);
      }
    }
  }, [costPrice, defaultCoupon]);

  // API FUNCTION:- To save user webinar data to dx- backend table called 'Webinar'
  const saveUserWebinarDetailsInDxBackend = async (
    name,
    email,
    phone,
    couponCode
  ) => {
    // const webinarPrice = coupanEnabled ? sellingPrice : costPrice;

    // As we are not giving option to user to select the speaker, so by default we are saving first speaker details to db
    // in speakerId & speakerName params. As speakers details are coming from "/api/schedular/speaker/${slotId}" api.

    // In Webinar Body, costPrice=0 and razorpayPaymentId=null because it is a free webinar

    const urlParams = queryString.parse(props.location.search);

    let webinarBody = {
      indiaClinicId: clientId ? clientId : "",
      name: name,
      email: email,
      phone: phone,
      productId: Number(serviceId),
      speakerId: speaker[0].id,
      speakerName: speaker[0].name,
      schedulerId: Number(schedularId),
      webinarTopic: selectedTopic,
      webinarDateTime: webinarTimeSave,
      language: "English",
      zoomLink: "",
      couponCode: couponCode,
      costPrice: 0,
      razorpayPaymentId: null,
      leadSource:
        urlParams.twinApp && urlParams.twinApp === "yes"
          ? "TwinApp"
          : "TwinWebsite",
    };
    try {
      await handleUserWebinarDataToDxBackend(webinarBody);
      return;
    } catch (error) {
      console.error("Error in user webinar func", error);
      return;
    }
  };

  const proceedForLSQEntry = (payload, lsqWebinarTiming) => {
    // LSQWebinarTiming
    try {
      let webinarTime = format(
        addMinutes(lsqWebinarTiming, lsqWebinarTiming.getTimezoneOffset()),
        "yyyy-MM-dd HH:mm:ss"
      );
      // console.log(webinarTime);
      let leadSource = "";
      let ctaLeadsource = "";
      if (appHeader === "mobile" || appHeader === "no" || twinApp === "yes") {
        leadSource = "PRE ENROLL APPLICATION";
        ctaLeadsource = "Free Webinar Pre-Enroll App"
      } else {
        leadSource = "New Website";
        ctaLeadsource = "Free Webinar Website"
      }

      const userName = payload.firstName + " " + payload.lastName || "";

      let leadSquaredParams = {
        FirstName: userName,
        Phone: payload.phone,
        EmailAddress: payload.email,
        mx_Website_CTA: ctaLeadsource,
        mx_Webinar_Lead_Captured: "Yes",
        mx_Webinar_datetime: webinarTime,
        mx_Latest_Source: leadSource,
      };
      sendToLSQ(leadSquaredParams);
    } catch (error) {
      console.log("lsq error");
    }
  };

  const proceedForBookWebinar = async (
    payload,
    webinarTiming,
    lsqWebinarTiming
  ) => {
    setShowLoader(true);
    setServerErr(null);

    // const urlParams = queryString.parse(props.location.search);

    const userName =
      payload.lastName && payload.lastName !== ""
        ? payload.firstName + " " + payload.lastName || ""
        : payload.firstName;
    const callbackUrl = getCallbackUrl(userName);

    // Function Call:- To save user data to dx-backend table called webinar
    saveUserWebinarDetailsInDxBackend(
      userName,
      payload.email,
      payload.phone,
      payload.couponCode
    );

    // Navigate to success page
    navigate(callbackUrl);

    setShowLoader(false);

    // LSQ Call
    proceedForLSQEntry(payload, lsqWebinarTiming);

    // CT Event
    // urlParams.twinApp && urlParams.twinApp === "yes"
    //   ? window.twinApp &&
    //     window.twinApp.postMessage(
    //       JSON.stringify({
    //         action: "cleverTapEvent",
    //         arguments: {
    //           event: "Webinar Registration Successful",
    //           payload: {
    //             action: JSON.stringify({
    //               msg: "created on successful webinar payment",
    //               clientId: payload.clientId,
    //               name: userName,
    //               email: payload.email,
    //               phone: payload.phone,
    //               webinarTimeSlot: webinarTiming,
    //               couponCode: payload.couponCode,
    //             }),
    //           },
    //         },
    //       })
    //     )
    //   : sendToCT("Webinar Registration Successful", {
    //       action: "Webinar_Payment_Success Clicked",
    //       clientId: payload.clientId,
    //       name: userName,
    //       email: payload.email,
    //       phone: payload.phone,
    //       webinarTimeSlot: webinarTiming,
    //       couponCode: payload.couponCode,
    //     });
  };

  const submitForm = ({ name, phone, email }) => {
    if (
      webinarTiming === "" ||
      webinarTiming === null ||
      webinarTiming === undefined
    ) {
      setWebinarTimingErr("Please select a date & time slot to proceed");
      return;
    }

    if (
      selectedTopic === "" ||
      selectedTopic === null ||
      selectedTopic === undefined
    ) {
      setWebinarTimingErr(null);
      setWebinarTopicErr("Please choose webinar topic.");
      return;
    }

    setWebinarTimingErr(null);
    setWebinarTopicErr(null);

    setName(name);
    setEmail(email);
    setPhone(phone);

    const splitedName = name.split(" ");
    const firstName = splitedName[0];
    const lastName = splitedName[1];

    const payload = {
      clientId: clientId || "",
      firstName: firstName,
      lastName: lastName || "",
      phone: phone,
      email: email,
      productId: serviceId,
      amount: coupanEnabled ? "" + sellingPrice : "" + costPrice,
      sourceType: "PROSPECT_MARKETING_SITE",
      couponEnabled: coupanEnabled,
      couponCode: coupon,
      schedularId: schedularId,
    };

    proceedForBookWebinar(payload, webinarTiming, LSQWebinarTiming);
    // ct-events
    // twinApp && twinApp === "yes"
    //   ? window.twinApp &&
    //     window.twinApp.postMessage(
    //       JSON.stringify({
    //         action: "cleverTapEvent",
    //         arguments: {
    //           event: "Webinar Registration Initiated",
    //           payload: {
    //             action: JSON.stringify({
    //               msg: "clicked on webinar registration button",
    //               clientId: clientId,
    //               name: name,
    //               email: email,
    //               phone: phone,
    //               webinarTimeSlot: webinarTiming,
    //               couponCode: coupon,
    //             }),
    //           },
    //         },
    //       })
    //     )
    //   : sendToCT("Webinar Registration Initiated", {
    //       action: "Webinar_Register_CTA Clicked",
    //       clientId: clientId,
    //       name: name,
    //       email: email,
    //       phone: phone,
    //       webinarTimeSlot: webinarTiming,
    //       couponCode: coupon,
    //     });

    // nudgify-event
    sendToNudgify(name, email);

    // pixel-integration
    // pixelEvent(coupanEnabled ? "" + sellingPrice : "" + costPrice);
  };

  const handleTopicChange = value => {
    setSelectedTopic(value);
    setSchedularId(null);
    setWebinarTiming(null);
    setWebinarTimeSave(null);
    setRedirectWebinarTiming("");
    setLSQWebinarTiming("");
  };
  const handleSelectedTopic = value => {
    setSelectedTopic(value);
  };

  const handleSlot = (slotName, value, status) => {
    // console.log(slotName, value, status);
    setSelectedOption(slotName);
  };

  const handleCouponUpdate = (price, couponVal, placeholder) => {
    const newSellingPrice = parseInt(costPrice) - parseInt(price);
    setCoupon(couponVal);
    setCouponPlaceholder(placeholder);
    setSellingPrice(newSellingPrice);
    setCoupanEnabled(true);
    setDiscountAmount(price);
  };

  const submitDateTimeForm = (
    schedularId,
    timing,
    actualTiming,
    webinarTiming
  ) => {
    const lsqDt = parse(webinarTiming, "dd-MM-yyyy HH:mm:ss", new Date());

    setSchedularId(schedularId);
    setWebinarTiming(timing);
    setRedirectWebinarTiming(actualTiming);
    setLSQWebinarTiming(lsqDt);
  };

  const handleShowForm = () => {
    setWebinarTimingErr(null);
    setWebinarTopicErr(null);
    setShowForm(false);
  };

  const handleFormPopupCta = () => {
    setShowForm(true);

    // Clevertap Event
    // if (twinApp && twinApp === "yes") {
    //   // For Twin App
    //   if (window.twinApp) {
    //     const ctData = JSON.stringify({
    //       action: "cleverTapEvent",
    //       arguments: {
    //         event: `Webinar Visited`,
    //         payload: {
    //           action: JSON.stringify({
    //             msg: "App_Webinar_Visited",
    //           }),
    //         },
    //       },
    //     });
    //     window.twinApp.postMessage(ctData);
    //   }
    // } else {
    //   sendToCT("Webinar Visited", {
    //     action: "Webinar_Visited_Register_Cta",
    //   });
    // }
  };

  return (
    <>
      <Seo
        title="Diabetes Webinar | Diabetes Reversal Program | Twin"
        description="Diabetes Webinar. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
      />
      <div className={styles.webinar__container}>
        {appHeader === "mobile" && (
          <AppHeader text="" isBack={true} backUrl="mobile_back" />
        )}
        {/* {appHeader === "mobile" ? (
          <AppHeader text="" isBack={true} backUrl="mobile_back" />
        ) : appHeader !== "no" ? (
          <WebinarHeader
            location={props.location}
            showHeaderTime={true}
            time={currentTime}
            className={styles.myHeader1}
            showForm={showForm}
            handleShowForm={handleShowForm}
          />
        ) : (
          <AppHeaderTwinApp isBack={false} />
        )} */}

        <div
          className={`${
            appHeader === "mobile" || appHeader === "no" ? "appHeader" : ""
          }`}
        >
          {showForm ? (
            // for mobile
            <div
              className={`col-md-12 px-0 mx-0 ${styles.formCoo} ${styles.rightC} ${styles.bgConCol}`}
            >
              {twinApp !== "yes" && appHeader !== "mobile" && (
                <WebinarHeader
                  location={props.location}
                  showHeaderTime={true}
                  time={currentTime}
                  // className={styles.myHeader1}
                  showForm={showForm}
                  handleShowForm={handleShowForm}
                />
              )}

              <WebinarForm
                submitForm={submitForm}
                // handleInput={handleInput}
                sellingPrice={sellingPrice}
                costPrice={costPrice}
                price={sellingPrice}
                selectedTopic={selectedTopic}
                webinarTopic={webinarTopic}
                handleTopicChange={handleTopicChange}
                handleSelectedTopic={handleSelectedTopic}
                name={name}
                email={email}
                phone={phone}
                showLoader={showLoader}
                coupanEnabled={coupanEnabled}
                handleCouponUpdate={handleCouponUpdate}
                handleSlot={handleSlot}
                schedular={schedular}
                selectedOption={selectedOption}
                serverErr={serverErr}
                couponList={couponList}
                coupon={coupon}
                couponPlaceholder={couponPlaceholder}
                submitDateTimeForm={submitDateTimeForm}
                discountAmount={discountAmount}
                webinarTiming={webinarTiming}
                webinarTimingErr={webinarTimingErr}
                webinarTopicErr={webinarTopicErr}
                pId={serviceId}
                serviceId={serviceId}
                twinApp={twinApp}
                appHeader={appHeader}
                handleShowForm={handleShowForm}
                showCouponSection={false}
                showCostPrice={showCostPrice}
              />
            </div>
          ) : (
            // for desktop
            <div className="row px-0 mx-0">
              <div className={`col-md-9 px-0 mx-0 ${styles.leftC}`}>
                <WebinarHeader
                  location={props.location}
                  showHeaderTime={true}
                  time={currentTime}
                  className={
                    appHeader === "no" || appHeader === "mobile"
                      ? styles.hideD
                      : styles.showD
                  }
                  showForm={showForm}
                  handleShowForm={handleShowForm}
                />

                <WebinarLiveCount webinarData={webinarData} />
                <WebinarHost speaker={speaker} isHostLoading={isHostLoading} />

                {/* <div className="d-none d-md-block d-lg-block">
                  <WebinarTimer time={currentTime} />
                </div> */}

                <SuccessStories
                  carouselId="webinarSuccessStories"
                  bgColor="webinarStories"
                />

                <WebinarBenifits />

                <OurSuccess />
                {/* <Reviews /> */}
                <KeyTakeAways />
              </div>

              <div
                className={`col-md-3 px-0 mx-0 ${styles.rightC} ${styles.bgConCol}`}
              >
                {/* for desktop */}
                <div className="d-none d-md-block d-lg-block">
                  <WebinarForm
                    submitForm={submitForm}
                    // handleInput={handleInput}
                    sellingPrice={sellingPrice}
                    costPrice={costPrice}
                    price={sellingPrice}
                    name={name}
                    email={email}
                    phone={phone}
                    selectedTopic={selectedTopic}
                    webinarTopic={webinarTopic}
                    handleTopicChange={handleTopicChange}
                    handleSelectedTopic={handleSelectedTopic}
                    showLoader={showLoader}
                    coupanEnabled={coupanEnabled}
                    handleCouponUpdate={handleCouponUpdate}
                    handleSlot={handleSlot}
                    schedular={schedular}
                    selectedOption={selectedOption}
                    serverErr={serverErr}
                    couponList={couponList}
                    coupon={coupon}
                    couponPlaceholder={couponPlaceholder}
                    submitDateTimeForm={submitDateTimeForm}
                    discountAmount={discountAmount}
                    webinarTiming={webinarTiming}
                    webinarTimingErr={webinarTimingErr}
                    webinarTopicErr={webinarTopicErr}
                    pId={serviceId}
                    serviceId={serviceId}
                    twinApp={twinApp}
                    appHeader={appHeader}
                    handleShowForm={handleShowForm}
                    showCouponSection={false}
                    showCostPrice={showCostPrice}
                  />
                </div>
              </div>
              <div className="col-12">
                {/* for mobile cta */}
                <div className="d-block d-lg-none d-xl-none d-xxl-none">
                  <div
                    className={`${styles.bottomfix}`}
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  >
                    {/* <div className="text-center">
                      <p className={styles.finaltext}>
                        <Image
                          secure="true"
                          cloudName={config.CLOUDINARY_NAME}
                          publicId="Webinar/g_tick_dhhhzp.svg"
                          dpr="auto"
                          responsive
                          width="auto"
                          crop="scale"
                          responsiveUseBreakpoints="true"
                          fetchFormat="webp"
                          quality="auto"
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                        >
                          <Placeholder type="blur"></Placeholder>
                        </Image>
                        100% MONEY-BACK GUARANTEE{" "}
                      </p>
                    </div> */}

                    <button
                      onClick={handleFormPopupCta}
                      className={styles.popup_btn}
                    >
                      {showLoader ? (
                        <div>
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ left: "50%", top: "50%" }}
                          >
                            {/* <span className="sr-only">Loading...</span> */}
                          </Spinner>
                        </div>
                      ) : twinApp && twinApp === "yes" ? (
                        <>Register for webinar</>
                      ) : (
                        <>Register for webinar</>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <iframe
        src="https://trk.y2k1.xyz/pixel?adid=61e7ffa3dbf0860904673ce8"
        scrolling="no"
        frameborder="0"
        width="1"
        height="1"
        title="pixelWebinarIFrame"
      ></iframe>
    </>
  );
};

export default WebinarFreePage;
